import "../styles/Dashboard.css";

import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import Header from "./Header";
import TopBar from "./TopBar";
import AlertsTable from "./AlertsTable";
import Map from "./Map";
import Button from "@mui/material/Button";

const Dashboard = () => {
  const [alerts, setAlerts] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState({});
  const { logoutUser } = useContext(AuthContext);
  const [updateCount, setUpdateCount] = useState(false);
  const [activeAlert, setActiveAlert] = useState(false);

  const [monitoringStatus, setMonitoringStatus] = useState("Checking...");

  useEffect(() => {
    const fetchMonitoringStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/checkHeartbeat`, {
          method: "GET",
          headers: getHeaders(),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setMonitoringStatus(result.status);
      } catch (error) {
        console.error("Could not fetch monitoring status:", error);
        setMonitoringStatus("Server Error");
      }
    };

    fetchMonitoringStatus();
    const intervalId = setInterval(fetchMonitoringStatus, 30000); // 30 seconds

    return () => clearInterval(intervalId);
  }, []);

  const transformData = (data) => data.map((item) => ({
    deviceid: item.device_id,
    time: new Date(item.alert_time).toLocaleString(),
    name: item.name,
    address: item.address,
    gps: {
      lat: parseFloat(item.gps.split(",")[0]),
      long: parseFloat(item.gps.split(",")[1]),
    },
    alertType: item.alert_type,
    status: item.status,
    macAddress: item.mac_address,
    alert_id: item.alert_id,
  }));

  useEffect(() => {

    const statusOrder = {
      active: 0,
      unassigned: 1,
      "in progress": 2,
      "no resolution": 3,
      resolved: 4,
    };

    const fetchAlerts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/alerts`, {
          method: "GET",
          headers: getHeaders(),
        });
  
        if (!response.ok) {
          if (response.status === 401) {
            logoutUser();
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data);
        const transformedData = transformData(data);
  
        transformedData.sort((a, b) => statusOrder[a.status.toLowerCase()] - statusOrder[b.status.toLowerCase()]);

        setAlerts(transformedData);
      } catch (error) {
        console.error("Could not fetch alerts:", error);
      }
    };
  
    fetchAlerts();
    const timer = setInterval(fetchAlerts, 10000);
    return () => clearInterval(timer);
  }, [updateCount, logoutUser]);

  useEffect(() => {
    console.log(alerts);
  }, [alerts]);

  useEffect(() => {
    setActiveAlert(
      alerts.some((alert) => alert.status.toLowerCase() === "active")
    );
  }, [alerts]);

  function getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("authTokens")).access
      }`,
    };
  }

  const updateSelected = (alert_id) => {
    const alert = alerts.find((item) => item.alert_id === alert_id);
    if (alert.alert_id !== selectedAlert.alert_id) {
      setSelectedAlert(alert);
    }
  };

  const updateStatus = async (status, alert_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/api/alerts/updateStatus/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(localStorage.getItem("authTokens")).access,
          },
          body: JSON.stringify({
            status: status,
            alert_id: selectedAlert.alert_id,
          }),
        }
      );

      if (response.status === 401) {
        logoutUser();
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Could not update status:", error);
    }
    setUpdateCount((prevCount) => prevCount + 1);
  };

  return (
    <div
      className={`alert-page-container ${activeAlert ? "active" : ""}`}
    >
      <Header logoutUser={logoutUser} monitoringStatus={monitoringStatus}/>
      <div className="dashboard-container">
        <div className={`dashboard`}>

          <main className="">
            <div className="topbar">
              <TopBar updateStatus={updateStatus} selectedAlert={selectedAlert} />
            </div>
            <AlertsTable
              alerts={alerts}
              updateSelected={updateSelected}
              selected={selectedAlert}
            />
          </main>
          </div>
        <div className={`location-container`}>
          <div className="location-header">
            <div className="map google-button">
            {/* <Button
                className="button"
                variant="contained"
                onClick={() => setMapHide((currState) => !currState)}
              >{mapHide ? "▲" : "▼"}</Button> */}
            </div>
            <h2 className="map title">Selected Location</h2>
            <div className="map hide-button">
              
              <Button
                disabled={!selectedAlert.gps}
                className="button"
                variant="contained"
                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${selectedAlert.gps.lat},${selectedAlert.gps.long}`)}
              >Maps</Button>
            </div>
            
          </div>
          <div className={`map-container`}>
            <Map activeAlert={selectedAlert}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;