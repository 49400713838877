import React, {useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import '../styles/Login.css'

const Login = () => {

  let {loginUser, user} = useContext(AuthContext)
  let navigate = useNavigate()

  useEffect(() => {
    if(user) {
      console.log(user)
      navigate("/")
    }
  },[user, navigate])

  return (
    <div className="page-container">
      <div className="login-container">
        <div className="title-container">
          <h1 className="login-title">Login</h1>
          <p className="message">Enter your email below to login to your account</p>
        </div>
        <form onSubmit={loginUser} className="form">

          <div className="input-group">
            <label for="email">Email</label>
            <input type="email" name="email" id="email" />
          </div>

          <div class="input-group input-group-password">
            <label for="password">Password</label>
            <input type="password" id="password" name="password" />
            <a className="forgot-password" href="/forgot-password">Forgot Password?</a>
          </div>
          
          <button type="submit" className='login-button'>Login</button>
        </form>
      </div>
    </div>
  )
}

export default Login