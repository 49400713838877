import React from "react";
import "../styles/AlertsTable.css";

function formatTime(date) {
  try {
    const now = new Date();
    const options = { hour: '2-digit', minute: '2-digit' };

    if (now.toDateString() === date.toDateString()) {
      return `Today, ${date.toLocaleTimeString('en-US', options)}`;
    } else if (now.getDate() - 1 === date.getDate()) {
      return `Yesterday, ${date.toLocaleTimeString('en-US', options)}`;
    } else {
      return date.toLocaleDateString('en-US', options);
    }
  } catch (error) {
    return date;
  }
}

const AlertsTable = ({ alerts, updateSelected, selected }) => {
  
  const tableData = alerts.map((item, index) => {
    return (
      <div
        key={item.alert_id}
        className={`alert-card ${item.status.toLowerCase() === "active" ? 'active' : ''} ${selected?.alert_id === item.alert_id ? 'selected' : ''}`}
        onClick={() => updateSelected(item.alert_id)}
      ><div className="primary-info">
        <div className="alert-type">{item.alertType}</div>
        <div className="alert-name">{item.name}</div>
        <div className="alert-address">{item.address}</div>
        </div>
        <div className="secondary-info">
        {/* <div className="alert-data gps">{`Lat: ${item.gps.lat} - Long: ${item.gps.long}`}</div> */}
        <div className="alert-status">{item.status}</div>
        <div className="alert-time">{formatTime(new Date(item.time))}</div>
        </div>
      </div>
    );
  });

  return (
    <div className="alert-cards">{tableData}</div>
  );
};

export default AlertsTable;
