import { MapContainer, TileLayer, useMap, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../styles/Map.css";
import { useEffect, useState } from "react";
import L from "leaflet";

// Manually set the icon URLs
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Custom hook to update the map center
function ChangeView({ center }) {
  const map = useMap();
  map.setView(center);
  return null;
}

const Map = ({ activeAlert }) => {
  const [position, setPosition] = useState([41.06016170658931, -124.14277888907425]);

  useEffect(() => {
    const location = activeAlert?.gps;
    if (location?.lat && location?.long) {
      setPosition([location.lat, location.long]);
    }
    console.log(location);
  }, [activeAlert]);

  return (
      <MapContainer id="leaf-map" center={position} zoom={20} scrollWheelZoom={true} attributionControl={false}>
        <ChangeView center={position} />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position}>
          {/* <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        </Marker>
      </MapContainer>
  );
};

export default Map;