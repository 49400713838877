import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from './components/Dashboard';
import Login from './components/Login';
import NotFound from './components/NotFound';

import PrivateRoutes from "./utils/PrivateRoutes";
import { AuthProvider } from "./context/AuthContext";

const App = () => {
  
  return (
    <Router>
        <AuthProvider>
          <Routes>
            <Route element={<PrivateRoutes />} exact>
              <Route path="/" element={<Dashboard />} exact />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
    </Router>
  );
};

export default App;
