import React from "react";
import "../styles/Header.css";
import { Button } from "@mui/material";
import { toTitleCase } from "../utils/utils";

const Header = ({logoutUser, monitoringStatus}) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const time = new Date().toLocaleString("en-US", options);

  return (
    <header className="">
      <div className="station">
        <h1 className="title">Alert Dashboard</h1>
      </div>
      <div className="status-container">
        <span className={`status ${monitoringStatus?.toLowerCase()}`}>Monitoring status: {toTitleCase(monitoringStatus)}</span>
        <span>|</span>
        <span className="last-login">
          Last login: 02/24/2023 10:30 AM from 192.168.1.101
        </span>
      </div>
      <div className="time-container">
        <span className="time">Current Local Time: {time}</span>
      </div>
      <div className="logout-container">
        <Button
          className="button"
          variant="contained"
          color="error"
          onClick={logoutUser}
        >
          Logout
        </Button>
      </div>
    </header>
  );
};

export default Header;
