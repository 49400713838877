import React, { useEffect } from "react";
import { Button } from "@mui/material";
import "../styles/TopBar.css";

const TopBar = ({ updateStatus, selectedAlert }) => {

  useEffect(() => {
    console.log("Selected Alert: ", selectedAlert);
  }
  , [selectedAlert]);

  return (
    <div className="container">
      <h2 className="title">Set Status</h2>
      <div className="buttons">
        <Button
          disabled={!selectedAlert || Object.keys(selectedAlert).length === 0}
          className="button"
          variant="contained"
          onClick={() => updateStatus("In Progress")}
        >
          In Progress
        </Button>
        <Button
          disabled={!selectedAlert || Object.keys(selectedAlert).length === 0}
          className="button"
          variant="contained"
          onClick={() => updateStatus("Resolved")}
        >
          Resolved
        </Button>
        <Button
          disabled={!selectedAlert || Object.keys(selectedAlert).length === 0}
          className="button"
          variant="contained"
          onClick={() => updateStatus("No Resolution")}
        >
          No Resolution
        </Button>
        <Button
          disabled={!selectedAlert || Object.keys(selectedAlert).length === 0}
          className="button"
          variant="contained"
          onClick={() => updateStatus("Active")}
        >
          Active
        </Button>
        <Button
          disabled={!selectedAlert || Object.keys(selectedAlert).length === 0}
          className="button"
          variant="contained"
          onClick={() => alert("Request Sent!")}
        >
          Send Request
        </Button>
      </div>
    </div>
  );
};

export default TopBar;
